<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="modal-select2"
      title="Choix d'option supplémentaire"
      ok-title="Enregistrer"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      size="lg"
      @ok="submit"
      @show="fillDataIfProvided"
      no-close-on-backdrop
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <required-text-input-field
              v-model="choice.title"
              name="title"
              label="Titre du choix"
              label-for="title"
              placeholder="Titre du choix"
            />
          </b-col>

          <b-col md="6">
            <b-form-group label="Prix" label-for="price">
              <b-form-input
                id="price"
                placeholder="Prix"
                :value="price"
                @change="setPrice($event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="description"
              description="Limite de 160 caractères"
            >
              <b-form-textarea
                rows="4"
                size="sm"
                maxlength="160"
                v-model="choice.description"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <div class="mt-1">
              <b-form-checkbox
                v-model="choice.is_active"
                class="mr-0 mt-50"
                name="is_active"
                switch
                inline
              >
                Publié
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col md="4">
            <div class="mt-1">
              <b-form-checkbox
                v-model="choice.has_inventory"
                class="mr-0 mt-50"
                name="has_inventory"
                switch
                inline
              >
                Inventaire limité
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col md="4" v-if="choice.has_inventory">
            <b-form-group
              label="Inventaire maximal"
              label-for="inventory_count"
            >
              <b-form-input
                id="inventory_count"
                type="number"
                placeholder="Inventaire"
                v-model="choice.inventory_count"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              v-if="products.length > 0"
              label="Produit associé"
              label-for="product_id"
              class="mt-1"
            >
              <v-select
                id="product_id"
                ref="product_id"
                placeholder="Liste des produits"
                v-model="choice.product_id"
                :options="products"
                label="label"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BAlert,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import groupCreationConfig from "@core/data/groupCreationConfig.js";

import { ValidationObserver } from "vee-validate";
import { useValidatorsWithAutoFormDataCleanUp } from "@/shared/composables/use-validators";

import { required } from "@validations";
import { reactive } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RequiredTextInputField from "@/shared/components/RequiredTextInputField";
import vSelect from "vue-select";

export default {
  components: {
    BAlert,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BFormInvalidFeedback,
    ToastificationContent,
    ValidationObserver,
    RequiredTextInputField,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      groupCreationConfig,
    };
  },
  props: {
    currentChoice: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    action: {
      type: String,
      default: "create",
    },
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, { emit }) {
    let initialState = {
      title: null,
      description: null,
      is_active: true,
      has_inventory: false,
      inventory_count: 0,
      price: null,
      product_id: null,
    };

    const choice = reactive({ ...initialState });

    const fillDataIfProvided = () => {
      if (props.action === "update") {
        Object.assign(choice, props.currentChoice);
      } else {
        Object.assign(choice, initialState);
      }
    };

    const submit = async () => {
      try {
        if (props.action === "update") {
          emit("edit", choice);
        } else {
          emit("create", choice);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const { refFormObserver } = useValidatorsWithAutoFormDataCleanUp(choice);

    return {
      submit,
      choice,
      fillDataIfProvided,
      refFormObserver,
      required,
    };
  },
  computed: {
    price: {
      get() {
        let price = 0.0;
        if (this.choice.price) {
          price = this.choice.price / 100;
        }
        return price.toFixed(2);
      },
      set() {},
    },
  },
  methods: {
    setPrice(newValue) {
      this.choice.price = parseInt(parseFloat(newValue) * 100);
    },
  },
};
</script>
